import { Box, IconButton, Typography } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import FramedContainer from "../framed-container/FramedContainer";
import MuiMarkdown from "mui-markdown";
import successToast from "../toasts/SuccessToast";
import errorToast from "../toasts/ErrorToast";
import { useState } from "react";
import { AuthStorage } from "../../storage/authStorage";

function ContentPlanningFeedbackForm({ contentPlanning, contentPlanningId }) {
  const authStorage = new AuthStorage();
  const [isFeedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const saveFeedback = (rate) => {
    const baseUrl = process.env.REACT_APP_API_URL + "/feedback";
    const headers = new Headers({
      "Content-Type": "application/json",
      "Authorization-id": `Bearer ${authStorage.getUserToken()}`,
    });
    fetch(baseUrl, {
      method: "POST",
      headers,
      body: JSON.stringify({ rate, content_planning_id: contentPlanningId }),
    })
      .then((response) => response.json())
      .then(() => {
        setFeedbackSubmitted(true);
        successToast("Feedback enviado.");
      })
      .catch(() => {
        setFeedbackSubmitted(false);
        errorToast("Ocurrió un problema al enviar el feedback.");
      });
  };

  const hasContentPlanning = () => contentPlanning !== "";

  const renderFeedbackOptions = () => {
    if (isFeedbackSubmitted) {
      return (
        <Typography
          variant="caption"
          gutterBottom
          sx={{ display: "block", color: "green" }}
        >
          ¡Gracias!
        </Typography>
      );
    }
    return (
      <>
        <IconButton onClick={() => saveFeedback("useful")}>
          <ThumbUpIcon />
        </IconButton>

        <IconButton onClick={() => saveFeedback("not_useful")}>
          <ThumbDownIcon />
        </IconButton>
      </>
    );
  };

  const renderFeedbackForm = () => {
    return (
      <Box>
        <FramedContainer>
          <Box sx={{ width: "100%" }}>
            <MuiMarkdown>{contentPlanning}</MuiMarkdown>
          </Box>
        </FramedContainer>
        <FramedContainer>
          <Box sx={{ display: "flex", flexDirection: "row" }} component="form">
            <Typography
              variant="body1"
              sx={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              ¿Te resultó útil esta planificación?
            </Typography>

            {renderFeedbackOptions()}
          </Box>
        </FramedContainer>
      </Box>
    );
  };

  return hasContentPlanning() ? renderFeedbackForm() : undefined;
}

export default ContentPlanningFeedbackForm;
