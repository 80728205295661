import { Box, InputLabel, MenuItem, Select as MuiSelect } from "@mui/material";

function Select({ id, label, placeholder, value, options, onChange }) {
  return (
    <Box width="100%">
      <InputLabel id={id}>{label}</InputLabel>
      <MuiSelect
        displayEmpty
        labelId={id}
        id={`${label}-options`}
        value={value}
        onChange={onChange}
        fullWidth
        variant="outlined"
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {options.map(({ value, label }) => (
          <MenuItem value={value} key={value}>{label}</MenuItem>
        ))}
      </MuiSelect>
    </Box>
  );
}

export default Select;
