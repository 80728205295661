import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import {
  Alert,
  CircularProgress,
  Grid2,
  ListItem,
  ListItemText,
} from "@mui/material";
import { AuthStorage } from "../storage/authStorage";

function ContextDetail() {
  const [contextFiles, setContextFiles] = useState([]);
  const [isFetchingContextFiles, setFetchingContextFiles] = useState(true);

  useEffect(() => {
    const authStorage = new AuthStorage();

    const getContextFiles = () => {
      const baseUrl = process.env.REACT_APP_API_URL + "/context-files";
      setFetchingContextFiles(true);
      const headers = new Headers({
        "Content-Type": "application/json",
        "Authorization-id": authStorage.getUserId(),
        Authorization: `Bearer ${authStorage.getUserToken()}`,
      });
      fetch(baseUrl, {
        method: "GET",
        headers,
      })
        .then((response) => response.json())
        .then(({ content }) => {
          setFetchingContextFiles(false);
          setContextFiles(content);
        })
        .catch(() => {
          setFetchingContextFiles(false);
          setContextFiles([]);
        });
    };

    getContextFiles();
  }, []);

  const renderFileLinks = () => {
    if (isFetchingContextFiles) {
      return <CircularProgress />;
    } else if (contextFiles.length === 0) {
      return (
        <Alert severity="error" sx={{ marginTop: "15px" }}>
          No pudimos obtener los documentos.
        </Alert>
      );
    }

    return contextFiles.map((file) => (
      <ListItem key={file}>
        <ListItemText primary={file} />
      </ListItem>
    ));
  };

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
      <Grid2 container spacing={2}>
        <Grid2 item xs={12} md={6}>
          <Typography variant="h4">Contexto</Typography>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Documentación utilizada como contexto para generar las respuestas.
          </Typography>

          <List>{renderFileLinks()}</List>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default ContextDetail;
