import "./App.css";
import React, { useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ContentPlanning from "./screens/ContentPlanning";
import ContentArea from "./components/ContentArea";
import ContextDetail from "./screens/ContextDetail";

function App() {
  const [selectedItem, setSelectedItem] = useState("Planificación");

  const renderDrawerItem = (text, icon) => {
    const isSelected = selectedItem === text;
    return (
      <ListItem
        key={text}
        disablePadding
        selected={isSelected}
        onClick={() => setSelectedItem(text)}
      >
        <ListItemButton selected={isSelected}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text} />
        </ListItemButton>
      </ListItem>
    );
  };

  const renderSelectedContent = () => {
    if (selectedItem === "Contexto") {
      return <ContextDetail />;
    }
    return <ContentPlanning />;
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        sx={{
          width: 240,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: 240, boxSizing: "border-box" },
        }}
      >
        <Toolbar />
        <List>
          {renderDrawerItem("Contexto", <ArticleIcon />)}
          {renderDrawerItem("Planificación", <EventNoteIcon />)}
        </List>
      </Drawer>
      <ContentArea>{renderSelectedContent()}</ContentArea>
    </Box>
  );
}

export default App;
