import { Alert, Box } from "@mui/material";
import { useState } from "react";
import FilledButton from "../buttons/FilledButton";
import Select from "../fields/Select";
import Text from "../fields/Text";
import FramedContainer from "../framed-container/FramedContainer";
import { AuthStorage } from "../../storage/authStorage";

function ContentPlanningForm({ onContentPlanningChange }) {
  const [isFetchingContentPlanning, setFetchingContentPlanning] =
    useState(false);
  const [subject, setSubject] = useState("");
  const [grade, setGrade] = useState("");
  const [region, setRegion] = useState("");
  const [instruction, setInstruction] = useState("");
  const [topic, setTopic] = useState("");
  const [timeUnit, setTimeUnit] = useState();
  const [formError, setFormError] = useState(false);
  const authStorage = new AuthStorage();

  const labelFrom = (value, options) => {
    const selectedOption = options.find((option) => option.value === value);
    return selectedOption.label;
  };

  const onClick = () => {
    const baseUrl = process.env.REACT_APP_API_URL + "/content-planning";
    setFetchingContentPlanning(true);
    const body = {
      subject: labelFrom(subject, subjectOptions),
      grade: labelFrom(grade, gradeOptions),
      region: labelFrom(region, regionOptions),
      instruction,
      user_id: authStorage.getUserId(),
      topic,
      time_unit: timeUnit
    };
    const headers = new Headers({
      "Content-Type": "application/json",
      "Authorization-id": authStorage.getUserId(),
      Authorization: `Bearer ${authStorage.getUserToken()}`,
    });
    fetch(baseUrl, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        setFormError(false);
        onContentPlanningChange(data.content_planning);
        setFetchingContentPlanning(false);
      })
      .catch(() => {
        setFormError(true);
        onContentPlanningChange("");
        setFetchingContentPlanning(false);
      });
  };

  const updateField = (setField) => (event) => {
    setField(event.target.value);
  };

  const renderError = () => {
    return formError ? (
      <Alert severity="error" sx={{ marginTop: "15px" }}>
        Ocurrió un error al generar la respuesta.
      </Alert>
    ) : undefined;
  };

  const isSubmitButtonEnabled =
    subject &&
    grade &&
    region &&
    instruction &&
    topic &&
    timeUnit &&
    !isFetchingContentPlanning;

  const gradeOptions = [
    { value: "firstGrade", label: "Primer grado" },
    { value: "secondGrade", label: "Segundo grado" },
    { value: "thirdGrade", label: "Tercer grado" },
    { value: "fourthGrade", label: "Cuarto grado" },
    { value: "fifthGrade", label: "Quinto grado" },
    { value: "sixthGrade", label: "Sexto grado" },
    { value: "seventhGrade", label: "Séptimo grado" },
  ];

  const subjectOptions = [
    { value: "math", label: "Matemática" },
    { value: "literature", label: "Lengua" },
  ];

  const regionOptions = [
    { value: "caba", label: "CABA" },
    { value: "mendoza", label: "Mendoza" },
  ];

  return (
    <FramedContainer>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ width: "100%" }} component="form">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              gap: "10px",
            }}
          >
            <Select
              id="region"
              label="Región"
              placeholder="Seleccioná una región"
              value={region}
              onChange={updateField(setRegion, regionOptions)}
              options={regionOptions}
            />
            <Select
              id="grade"
              label="Grado"
              placeholder="Seleccioná un grado"
              value={grade}
              onChange={updateField(setGrade, gradeOptions)}
              options={gradeOptions}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              gap: "10px",
              marginTop: "15px",
            }}
          >
            <Select
              id="subject"
              label="Materia"
              placeholder="Seleccioná una materia"
              value={subject}
              onChange={updateField(setSubject, subjectOptions)}
              options={subjectOptions}
            />
            <Text
              id="topic"
              label="Tema"
              value={topic}
              onChange={updateField(setTopic)}
            />
          </Box>

          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <Text
              id="timeUnit"
              label="Unidad de tiempo"
              value={timeUnit}
              onChange={updateField(setTimeUnit)}
              type="number"
              placeholder="¿En cuantos encuentros (instrucciones) se pretende ejecutar esta planificación?"
            />
          </Box>

          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <Text
              id="instruction"
              label="Instrucción"
              value={instruction}
              onChange={updateField(setInstruction)}
              placeholder="¿Podrías armarme una planificación para enseñar funciones cuadráticas?"
            />
          </Box>
        </Box>

        <Box sx={{ marginTop: "30px" }}>
          <FilledButton onClick={onClick} disabled={!isSubmitButtonEnabled}>
            {!isFetchingContentPlanning ? "Generar" : "Generando..."}
          </FilledButton>

          {renderError()}
        </Box>
      </Box>
    </FramedContainer>
  );
}

export default ContentPlanningForm;
