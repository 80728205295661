import React, { useState } from "react";
import ContentPlanningForm from "../components/forms/ContentPlanningForm";
import ContentPlanningFeedbackForm from "../components/forms/ContentPlanningFeedbackForm";
import Typography from "@mui/material/Typography";

function ContentPlanning() {
  const [contentPlanning, setContentPlanning] = useState("");
  const [contentPlanningId, setContentPlanningId] = useState("");

  // Combined handler to update both content and contentId
  const handleContentChange = (contentPlanning) => {
    setContentPlanning(contentPlanning.content);
    setContentPlanningId(contentPlanning.content_planning_id);
  };

  return (
    <>
      <Typography variant="h4">Planificación</Typography>
      <ContentPlanningForm onContentPlanningChange={handleContentChange} />
      <ContentPlanningFeedbackForm
        contentPlanning={contentPlanning}
        contentPlanningId={contentPlanningId}
      />
    </>
  );
}

export default ContentPlanning;
