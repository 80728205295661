import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import FirebaseUIReact from "firebaseui-react";
import * as firebaseConfiguration from "../firebase-configuration.json";
import { useNavigate } from "react-router-dom";
import { HOME_PATH } from "../routes";
import errorToast from "../components/toasts/ErrorToast";
import FramedContainer from "../components/framed-container/FramedContainer";
import { Box, Typography } from "@mui/material";
import { useAuth } from "../components/auth/AuthContext";
import User from "../app/User";

export default function LoginScreen() {
  initializeApp(firebaseConfiguration);
  const navigate = useNavigate();
  const auth = getAuth();
  const { login, logout } = useAuth();

  const configuration = {
    signInFlow: "popup",
    signInOptions: ["google.com"],
    callbacks: {
      signInSuccessWithAuthResult: (userCredential) => {
        const baseUrl = process.env.REACT_APP_API_URL + "/user";
        const user = new User(
          userCredential.user.uid,
          userCredential.user.accessToken,
          userCredential.user.displayName,
          userCredential.user.email,
        );
        fetch(baseUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: user.id(),
            token: user.token(),
            full_name: user.fullName(),
            email: user.email(),
          }),
        })
          .then(() => login(user))
          .then(() => navigate(HOME_PATH))
          .catch(() => errorToast("Ocurrió un error al crear un usuario."));
      },
      signInFailure: () => {
        logout();
        errorToast("Ocurrió un error al iniciar sesión.");
      },
    },
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <FramedContainer widthSize="xs">
        <Typography component="h4" variant="h6">
          ¡Bienvenido!
        </Typography>
        <Typography component="h4" variant="h6">
          Ingresá con tu cuenta para continuar.
        </Typography>

        <Box sx={{ marginTop: "15px" }}>
          <FirebaseUIReact auth={auth} config={configuration}></FirebaseUIReact>
        </Box>
      </FramedContainer>
    </Box>
  );
}
