import { enqueueSnackbar } from "notistack";

function errorToast(message) {
  return enqueueSnackbar(message, {
    variant: "error",
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
  });
}

export default errorToast;
