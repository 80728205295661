import { Box, Toolbar } from "@mui/material";
import React from "react";

function ContentArea({ children }) {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      {children}
    </Box>
  );
}

export default ContentArea;
