import { LocalStorage } from "./localStorage";

export class AuthStorage {
  TOKEN = "token";
  EMAIL = "email";
  FULL_NAME = "fullName";
  ID = "id";

  constructor() {
    this.localStorage = new LocalStorage();
  }

  saveUser(user) {
    this.localStorage.add(this.EMAIL, user.email());
    this.localStorage.add(this.TOKEN, user.token());
    this.localStorage.add(this.FULL_NAME, user.fullName());
    this.localStorage.add(this.ID, user.id());
  }

  removeUser() {
    this.localStorage.remove(this.EMAIL);
    this.localStorage.remove(this.TOKEN);
    this.localStorage.remove(this.FULL_NAME);
    this.localStorage.remove(this.ID);
  }

  getUserToken() {
    return this.localStorage.get(this.TOKEN);
  }

  getUserId() {
    return this.localStorage.get(this.ID);
  }
}
