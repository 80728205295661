import "./FramedContainer.css";
import { Container } from "@mui/material";

function FramedContainer({ children }) {
  return (
    <Container className="framed-container" disableGutters>
      {children}
    </Container>
  );
}

export default FramedContainer;
