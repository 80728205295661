import Button from "@mui/material/Button";

export default function FilledButton({ children, onClick, disabled = false }) {
  return (
    <Button
      color="primary"
      variant="contained"
      onClick={onClick}
      disabled={disabled}
    >
      {" "}
      {children}{" "}
    </Button>
  );
}
