import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginScreen from "./screens/Login";
import { AppBar, ThemeProvider, Toolbar, Typography } from "@mui/material";
import { HOME_PATH, LOGIN_PATH } from "./routes";
import { SnackbarProvider } from "notistack";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import { AuthProvider } from "./components/auth/AuthContext";
import lightTheme from "./themes/lightTheme";

const router = createBrowserRouter([
  {
    path: LOGIN_PATH,
    element: <LoginScreen />,
  },
  {
    path: HOME_PATH,
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={lightTheme}>
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              Educación Argentina
            </Typography>
          </Toolbar>
        </AppBar>
        <SnackbarProvider />
        <RouterProvider router={router} />
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
