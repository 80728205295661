import React, { createContext, useContext, useState } from "react";
import { AuthStorage } from "../../storage/authStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const authStorage = new AuthStorage();
  const isUserTokenStored = authStorage.getUserToken();
  const [isAuthenticated, setIsAuthenticated] = useState(isUserTokenStored);

  const login = (user) => {
    authStorage.saveUser(user);
    setIsAuthenticated(true);
  };
  const logout = () => {
    authStorage.removeUser();
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
