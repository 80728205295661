export class LocalStorage {
  PROJECT_NAME_PREFIX = "educacion";

  remove(key) {
    localStorage.removeItem(this._keyWithPrefix(key));
  }

  add(key, value) {
    localStorage.setItem(this._keyWithPrefix(key), value);
  }

  get(key) {
    return localStorage.getItem(this._keyWithPrefix(key));
  }

  _keyWithPrefix(key) {
    return `${this.PROJECT_NAME_PREFIX}-${key}`;
  }
}
